import React, { useState } from 'react'
import Select from '@ui-elem-js/Select.jsx'
import { str } from '@spa-ec-js/services/localeSvc/index'
import Button, { ButtonColors } from '@ui-elem/Button/Button'
import Link from '@ui-elem-js/Link'
import { navigation } from '@spa-ec-js/components/AppUtil/AppUtilMod.jsx'
import classNames from 'classnames'
import { SelectedBrandItem, BrandDetails } from './interfaces'
import { SmartEdit } from '@spa-core/contentSlot'
import { parseBrandData } from './utils'

enum Styles {
    FIND_MODEL_COLOR = 'find_model_color',
}

type Props = {
    brands: string
    mainTitle: string
    selectBrandTitle: string
    position: string
    brandsListForMobile: BrandDetails[]
    brandsList: BrandDetails[]
    smartEdit: SmartEdit
    showAllLink: string
}

const FindModel: React.FC<Props> = ({ brands, mainTitle, selectBrandTitle, position, smartEdit, showAllLink }) => {
    const placeholderString: string = str('phrase.select.brand')
    const showAllString: string = str('brand.list.title')
    const { brandNamesList, brandNamesListForMobile } = parseBrandData(
        showAllString,
        showAllLink,
        placeholderString,
        JSON.parse(brands),
    )

    const [selectedBrand, setSelectedBrand] = useState<string>()
    const [brandList] = useState<BrandDetails[]>(brandNamesList)
    const [selectedURL, setSelectedURL] = useState<string>(brandList?.[0].url)
    const [brandListForMobile] = useState<BrandDetails[]>(brandNamesListForMobile)

    const areaLeftOrRightPadding: string = position === 'TopLeftArea' ? ' md:mr-1' : ' md:ml-1'

    return (
        <div
            data-ssr-key={smartEdit.SSRKey}
            className="h-full smartEditComponent"
            data-smartedit-component-id={smartEdit.SSRKey}
            data-smartedit-component-uuid={smartEdit.elementUid}
            data-smartedit-catalog-version-uuid={smartEdit.contentSlotCatalogVersion}
            data-smartedit-component-type={smartEdit.componentParentType}
        >
            <div
                className={classNames(
                    'h-full p-2 md:flex md:justify-center md:items-center',
                    Styles.FIND_MODEL_COLOR,
                    areaLeftOrRightPadding,
                )}
                key={position + '-find-model-component'}
            >
                <div key={'inner-find-model-component'} className="flex flex-col md:flex-row justify-between lg:flex-col">
                    {mainTitle ? <div className="font-head" dangerouslySetInnerHTML={{ __html: mainTitle }} /> : ''}
                    {selectBrandTitle ? <div dangerouslySetInnerHTML={{ __html: selectBrandTitle }} /> : null}
                    <div className={'block md:hidden pb-2'}>
                        {brandListForMobile?.length > 0 ? (
                            <Select
                                name={'brandsSelectionForMobile'}
                                value={brandListForMobile[0].value}
                                items={brandListForMobile}
                                onChange={(item: SelectedBrandItem) => {
                                    setTimeout(() => {
                                        navigation.navigateTo(item.url)
                                    }, 100)
                                }}
                            />
                        ) : null}
                    </div>
                    <div className={'hidden md:flex lg:block'}>
                        {brandList?.length > 0 ? (
                            <div className={'py-4 lg:py-0 pr-4 lg:pr-0 '}>
                                <Select
                                    name={'brandsSelectionForDesktop'}
                                    value={selectedBrand}
                                    items={brandList}
                                    onChange={(item: SelectedBrandItem) => {
                                        setSelectedURL(item.url)
                                        setSelectedBrand(item.text)
                                    }}
                                />
                            </div>
                        ) : null}
                        <div className={'py-4 md:flex justify-center'}>
                            <Link to={selectedURL}>
                                <Button
                                    buttonColor={ButtonColors.TERTIARY}
                                    className={'e2e-ver-inkchooser-button'}
                                    buttonPadding={'px-12 py-4'}
                                    onClick={() => {}}
                                    buttonText={str('brand.find.button')}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FindModel
