import { BrandDetails, Brand } from '../interfaces'

export const parseBrandData = (showAllString: string, showAllLink: string, placeholderString: string, brands: Brand[]) => {
    const brandNamesList: BrandDetails[] = []
    const brandNamesListForMobile: BrandDetails[] = []

    const showAllBrandsOption: BrandDetails = {
        value: showAllString,
        text: showAllString,
        url: showAllLink,
    }

    brandNamesList.push(showAllBrandsOption)
    const selectYourBrandOption: BrandDetails = {
        value: placeholderString,
        text: placeholderString,
        url: '/',
    }
    brandNamesListForMobile.push(selectYourBrandOption)
    brandNamesListForMobile.push(showAllBrandsOption)

    if (brands?.length > 0) {
        brands.forEach((brand: Brand) => {
            const brandDetails: BrandDetails = {
                value: brand.name || '',
                text: brand.name || '',
                url: brand.url || '',
            }
            brandNamesList.push(brandDetails)
            brandNamesListForMobile.push(brandDetails)
        })
    }

    return { brandNamesList, brandNamesListForMobile }
}
