import PropTypes from 'prop-types';
import React from 'react';
import filter from 'lodash/filter';
import { getThemeFactory } from '@ic-theme';
import * as cls from './Select.styles';
import classNames from 'classnames';

const th = getThemeFactory('Select', {});

export default class Select extends React.Component {
  /**
   * propTypes
   * @property {string} src
   * @property {string} centered
   */
  static get propTypes() {
    return {
      name: PropTypes.string,
      /**
       * The menu options to be of the form { value: 'val', text: 'The is the val' }. Use null for the value to be the default value.
       */
      items: PropTypes.array,
      /**
       * Function that is a called when a new value is selected in the dropdown. This generally the one used when not posting a form.
       */
      onChange: PropTypes.func,
      /**
       * Function that is a called on submit, for instance when enter is pressed while select box is focused.
       */
      onSubmit: PropTypes.func,
      /**
       * Sets a particular option with this value.
       */
      textSize: PropTypes.string,
      /**
       * Sets a particular option with this value.
       */

      value: PropTypes.string,
    };
  }

  render() {
    let textSize = '16px';
    if (this.props.textSize === 'sm') {
      textSize = '14px';
    }
    return (
      <div style={{ fontSize: textSize }} className={classNames('e2e-ver-ui-select', cls.select)}>
        <form className={'small'} onSubmit={this.props.onSubmit}>
          <select
            name={this.props.name}
            id={this.props.name}
            key={this.props.name}
            className={classNames('w-full p-2', cls['select-css'])}
            onChange={(e) => {
              const value = e.target.value;
              const selectedItem = filter(this.props.items, (item) => (item.value ? item.value : item.code) === value)[0];
              selectedItem.altered = value !== this.props.value;
              this.props.onChange(selectedItem);
            }}
            defaultValue={this.props.value}
          >
            {this.props.items.map((item) => {
              const key = item.url || item.value || item.code;
              return (
                <option key={`option_${key}`} value={item.value ? item.value : item.code}>
                  {item.text ? item.text : item.name}
                </option>
              );
            })}
          </select>
        </form>
      </div>
    );
  }
}

Select.defaultProps = {
  items: [],
};
